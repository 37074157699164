.btn:focus {
  outline: none;
  box-shadow: none;
}

.react-player {
  margin-top: 40px;
  /* border: #aaa 1px solid; */
  padding: 10px;
  background-color: #000;
  border-radius: 20px;
  width: 100% !important;
  height: unset !important;
}

.accordion-button:focus {
  outline: none;
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  color: #cf4dff;
  background-color: #cf4dff30;
  box-shadow: inset 0 -1px 0 rgba(0,0,0,.125);
}

.reset-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.accordion {
  font-size: 24px !important;
  padding-bottom: 100px;
}

.accordion-button {
  font-size: 30px !important;
}

.title {
  font-size: 50px;
  font-weight: bold;
  color: #fff;
}

.panel-heading{
  padding: 0;
}


.gradientRow {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
}

.gradient{
  background-color: #000;
  background-image: url('~assets/images/dither.png');
  background-repeat: repeat-x;
  background-size: contain;
  margin-right: none !important;

}

.navbar {
  background-color: #000;
}

.navbar.navbar-expand.navbar-light {
  background-color: #000;
  height: 100vh;
  background-image: url('~assets/images/Bt8X.gif');
  /* background-size:contain; */

}

.pipedriveWebForms-iframe {
  border: none;
  height: 100%;
  /* max-width: 1024px; */
  min-height: 600px;
  overflow: hidden;
  width: 99%;
}

.pipedriveWebForms {
  display: flex;
  flex-direction: row;
  background-color: #000;
  /* margin-bottom: 50px; */
  /* border-radius: 10px; */
  padding: 100px;
}


output {
  background-color: black;
  background-image: radial-gradient(
    rgba(54, 54, 54, 0.75), black 120%
  );
  border-radius: 10px;
  min-height: 380px;
  margin: 0;
  overflow: hidden;
  padding: 2rem;
  color: white;
  font-size: 26px;
  font-family: 'VT323', monospace;
  text-shadow: 0 0 5px #C8C8C8;
}

output::after {
   content: "";
   position: absolute;
   top: 0;
   left: 0;
   width: 100vw;
   min-height: 380px;
   background: repeating-linear-gradient(
           0deg,
           rgba(black, 0.15),
           rgba(black, 0.15) 1px,
           transparent 1px,
           transparent 2px
   );
   pointer-events: none;
}

output::selection {
  background: #cf4dff;
  text-shadow: none;
}

.pxtitle {
  font-size: 50px;
  font-family: Px Grotesk Regular, sans-serif;
  font-weight: bold;
}

.demo-findout-title {
  font-size: 27px;
  font-family: Px Grotesk Regular, sans-serif;
  font-weight: bold;
}

.dlp {
  background-image: url('~assets/images/dlp.svg');
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  height: 500px;
  top: -150px;
  position: relative;
}

.navbar-brand {
  white-space: unset !important;
}

.test-pass-fail-result-text {
  display: flex;
  justify-content: center;
  align-items: center;
}
